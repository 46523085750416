import emailjs from "@emailjs/browser";
import React, { useRef, useState } from "react";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { EMAIL_JS_PUBLIC_KEY, EMAIL_JS_SERVICE_ID, EMAIL_JS_TEMPLATE_ID_FOR_CLIENT } from "../constants";
import Footer from "./Footer";

const Contact = () => {
	const formRef = useRef();
	const [form, setForm] = useState({
		name: "",
		email: "",
		message: "",
	});
	const [loading, setLoading] = useState(false);

	const handleChange = (e) => {
		const { target } = e;
		const { name, value } = target;
		setForm({
			...form,
			[name]: value,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setLoading(true);

		const username = form.name.trim();
		const user_email = form.email.trim();
		const user_message = form.message.trim();

		if (username === '' || user_email === '' || user_message === '') {
			setLoading(false);
			toast.error("Please fill all the fields.", {
				position: 'bottom-right',
			});
			return;
		}

		emailjs.send(EMAIL_JS_SERVICE_ID, EMAIL_JS_TEMPLATE_ID_FOR_CLIENT, {
			from_name: username,
			to_email: user_email,
			message: user_message,
		}, EMAIL_JS_PUBLIC_KEY)
			.then(
				() => {
					setLoading(false);
					toast.success("Message sent successfully!", {
						position: 'bottom-right',
					});
					setForm({
						name: "",
						email: "",
						message: "",
					});
				},
				(error) => {
					setLoading(false);
					console.error(error);
					toast.error("Uh, oh! Something went wrong. Please try again later.", {
						position: 'bottom-right',
					});
				}
			);
	};

	return (
		<div className="relative z-0 bg-gradient-to-b from-gray-800 to-black min-h-screen flex flex-col">
			<div className="flex-grow text-white contact overflow-x-hidden pt-12 px-4 sm:px-0" id="contact">
				<div className="z-10 w-full sm:w-[850px] m-auto p-8 rounded-2xl bg-gray-900 shadow-lg">
					<p className="text-lg font-light text-center">REACH OUT TO ME</p>
					<h2 className="text-5xl font-extrabold mt-2 bg-clip-text text-transparent bg-gradient-to-r from-green-400 to-blue-500 text-center">Contact</h2>
					<form
						ref={formRef}
						onSubmit={handleSubmit}
						className="mt-12 flex flex-col gap-8"
					>
						<label className="flex flex-col">
							<span className="font-medium mb-4">Your Name</span>
							<input
								type="text"
								name="name"
								value={form.name}
								onChange={handleChange}
								placeholder="Enter your name"
								className="py-4 px-6 rounded-lg outline-none border-none font-medium bg-gray-800 text-white"
								required
							/>
						</label>
						<label className="flex flex-col">
							<span className="font-medium mb-4">Your email</span>
							<input
								type="email"
								name="email"
								value={form.email}
								onChange={handleChange}
								placeholder="Ex:abc@gmail.com"
								className="py-4 px-6 rounded-lg font-medium bg-gray-800 text-white"
								required
							/>
						</label>
						<label className="flex flex-col">
							<span className="font-medium mb-4">Your Message</span>
							<textarea
								rows={7}
								name="message"
								value={form.message}
								onChange={handleChange}
								placeholder="Hey! Got something on your mind? Drop me a line!"
								className="py-4 px-6 rounded-lg outline-none border-none font-medium bg-gray-800 text-white"
								required
							/>
						</label>
						<button
							type="submit"
							className="py-3 px-8 rounded-xl outline-none w-fit font-bold shadow-md bg-green-500 hover:bg-green-600 transition duration-200"
						>
							{loading ? "Sending..." : "Send"}
						</button>
					</form>
				</div>
				<ToastContainer />
			</div>
			<Footer />
		</div>
	);
};

export default Contact;
