import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { Link, useLocation } from 'react-router-dom';

const Navbar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    ReactGA.set({ page: pathname });
    ReactGA.pageview(pathname);
  }, [pathname]);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  return (
    <nav className="bg-gray-900 bg-opacity-80 backdrop-blur-lg p-4 text-white fixed w-full z-10 top-0 shadow-md transition-all ease-in-out duration-300">
      <div className="container mx-auto flex justify-between items-center px-4">
        {/* Desktop Menu */}
        <div className="hidden sm:flex space-x-6 items-center text-lg font-light">
          <Link to='/' onClick={closeMobileMenu} className="hover:text-indigo-400 transition duration-200">Home</Link>
          <Link to='/about' onClick={closeMobileMenu} className="hover:text-indigo-400 transition duration-200">About</Link>
          <Link to='/projects' onClick={closeMobileMenu} className="hover:text-indigo-400 transition duration-200">Projects</Link>
          <Link to='/experience' onClick={closeMobileMenu} className="hover:text-indigo-400 transition duration-200">Experience</Link>
          <Link to='/contact' onClick={closeMobileMenu} className="hover:text-indigo-400 transition duration-200">Contact</Link>
        </div>

        {/* Mobile Menu Button */}
        <div className="sm:hidden">
          <button onClick={toggleMobileMenu} className="text-2xl focus:outline-none transform transition duration-200">
            {isMobileMenuOpen ? '✕' : '☰'}
          </button>
        </div>

        {/* Name (Aligned to the Right) */}
        <div className="text-3xl sm:text-2xl font-extrabold text-right">
          <Link to='/' onClick={closeMobileMenu} className="nav-link hover:text-indigo-400 transition duration-200">Mouaad Ouammou</Link>
        </div>

        {/* Mobile Menu */}
        <div className={`sm:hidden fixed top-0 right-0 w-full h-full bg-gray-900 bg-opacity-95 text-center flex flex-col items-center justify-center transition-transform transform ${isMobileMenuOpen ? 'translate-x-0' : 'translate-x-full'} ease-in-out duration-300`}>
          <button onClick={closeMobileMenu} className="text-3xl absolute top-4 right-4 focus:outline-none">
            ✕
          </button>
          <ul className="font-medium text-2xl space-y-6">
            <li><Link to='/' onClick={closeMobileMenu} className="hover:text-indigo-400 transition duration-200">Home</Link></li>
            <li><Link to='/about' onClick={closeMobileMenu} className="hover:text-indigo-400 transition duration-200">About</Link></li>
            <li><Link to='/projects' onClick={closeMobileMenu} className="hover:text-indigo-400 transition duration-200">Projects</Link></li>
            <li><Link to='/experience' onClick={closeMobileMenu} className="hover:text-indigo-400 transition duration-200">Experience</Link></li>
            <li><Link to='/contact' onClick={closeMobileMenu} className="hover:text-indigo-400 transition duration-200">Contact</Link></li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
