import React from "react";
import vpn from '../assets/vpn.png';
import copeople from '../assets/copeople.png';
import Footer from './Footer';

const ProjectCard = ({ image, title, description, git, technologies }) => {
    return (
        <div className="max-w-sm bg-gray-900 border border-neutral-100 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            {title === 'Snap Shot' && (
                <a href="#">
                    <img className="w-full rounded-t-lg h-auto object-cover" src={vpn} alt="" />
                </a>
            )}
            {title === 'Co People' && (
                <a href="#">
                    <img className="w-full rounded-t-lg h-auto object-cover" src={copeople} alt="" />
                </a>
            )}
            <div className="p-4">
                <a href="#">
                    <h5 className="text-2xl font-bold tracking-tight text-white bg-clip-text text-transparent bg-gradient-to-r from-yellow-200 to-pink-500">{title}</h5>
                </a>
                <p className="font-normal text-sm text-gray-300 dark:text-gray-400">{description}</p>
            </div>
            <div className="m-2 flex justify-between">
                <div className="flex flex-wrap gap-2 pl-2">
                    {technologies.map((tag, index) => (
                        <p key={`${index}-${tag}`} className="text-[14px] text-blue-500">
                            #{tag}
                        </p>
                    ))}
                </div>
                <a href={git} className="text-red-300 border border-gray-200 rounded-lg shadow p-1 hover:text-green-500 duration-300">Project</a>
            </div>
        </div>
    );
};

const Projects = () => {
    return (
        <div className="flex flex-col min-h-screen bg-black">
            <div className="flex-grow flex flex-wrap gap-7 justify-center items-center m-12 p-12">
                {project.map((item, index) => (
                    <ProjectCard
                        key={index}
                        image={item.image}
                        title={item.title}
                        description={item.description}
                        links={item.links}
                        git={item.git}
                        technologies={item.technologies}
                    />
                ))}
            </div>
            <Footer />
        </div>
    );
}

export const project = [
    {
        title: 'Flight Reservation System',
        description: 'Development of a mobile application and website allowing users to search, book and manage their flights.',
        image: { vpn },
        git: 'https://github.com/mouad-ou/',
        technologies: ["Flutter", 'ReactJS', 'Spring Boot']
    },
    {
        title: 'Smart city bike application',
        description: 'SnapShot is a stunning portfolio that I exclusively designed using React JS and tailwind CSS.This Project serves as a representation of a photographer’s work, highlighting their portfolio and services.',
        image: { copeople },
        git: "https://github.com/mouad-ou/Smart-Bicycle-s-city",
        technologies: ["Flutter", 'Google Maps API', "Firebase", "Angular"]
    },
    {
        title: 'Disney+ Data Analytics Projects',
        description: 'In-depth analysis of Disney+ content.',
        image: { copeople },
        git: "https://www.kaggle.com/code/obviousg/notebook914112afc6",
        technologies: ["Python(pandas, Matplotlib, Seaborn)"]
    }
]

export default Projects;
