import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { experiences } from '../constants';
import Footer from './Footer';

const Experience = () => {
  return (
    <div className='experience bg-black text-white pt-4 overflow-x-hidden min-h-screen flex flex-col justify-between' id='experience'>
      {/* Experience Header */}
      <div className='flex-grow'>
        <div className='pt-6 px-4 sm:px-16'>
          <p className='font-light text-center sm:text-left text-lg sm:text-xl'>MY JOURNEY SO FAR.</p>
          <h2 className='text-3xl sm:text-5xl font-extrabold mt-2 text-center sm:text-left'>Work Experience.</h2>
        </div>
        {/* Timeline */}
        <VerticalTimeline className='mt-6 sm:mt-9'>
          {experiences.map((experience) => (
            <VerticalTimelineElement
              key={experience.role}
              className="vertical-timeline-element--work"
              contentStyle={{ background: "#1d1836", color: "#fff", borderRadius: '8px', padding: '1.5rem' }}
              contentArrowStyle={{ borderRight: "7px solid #232631" }}
              date={experience.duration}
              dateClassName='text-sm sm:text-base'
              iconStyle={{ background: '#fff', height: "80px", width: "80px" }} // Increased icon size
              icon={
                <a className='flex justify-center items-center w-full h-full' href={experience.url} target='_blank' rel="noopener noreferrer">
                  <img
                    src={experience.logo}
                    alt={experience.company}
                    className='w-[50%] h-[50%] object-contain' // Adjusted logo size
                  />
                </a>
              }
            >
              <div>
                <h3 className='text-white text-[24px] sm:text-[28px] font-bold'>{experience.role}</h3> {/* Increased role font size */}
                <p className='text-secondary text-[16px] sm:text-[18px] font-semibold' style={{ margin: 0 }}> {/* Increased company font size */}
                  {experience.company}
                </p>
              </div>

              <ul className='mt-4 sm:mt-5 list-disc ml-5 space-y-2'>
                {experience.points.map((point, index) => (
                  <li key={`experience-point-${index}`} className='text-white-100 text-[14px] sm:text-[16px] tracking-wider'> {/* Increased point font size */}
                    {point}
                  </li>
                ))}
              </ul>
            </VerticalTimelineElement>
          ))}
        </VerticalTimeline>
      </div>
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Experience;
