
export const services = [
    {
        title: "Technology Enthusiast",
        icon: 'https://csgeeek.github.io/web.png',
    },
    {
        title: "Software Engineer",
        icon: 'https://csgeeek.github.io/web.png',
    },
    {
        title: "Surf Coach",
        icon: 'https://csgeeek.github.io/web.png',
    },
];

export const name = 'Mouaad';

export const experiences = [
    
    {
        'company': 'Norsys Afrique',
        'role': 'Backend Developer SaaS Intern',
        'duration': 'July 2024 - September 2024',
        'logo': 'https://welovedevs.com/_next/image?url=https%3A%2F%2Fprocess.filestackapi.com%2Fresize%3Dwidth%3A300%2Foutput%3Dquality%3A80%2Fno_metadata%2Fauto_image%2Fcompress%2FKwwW6wPQTpGBXpWFqhvg&w=1200&q=75',
        'points': [
            'Development of a software solution as a service (SaaS) specifically designed for small businesses ☁️.',
        ],
        'url': 'https://www.norsys.fr/',
    },
    {
        'company': 'Capgemini',
        'role': 'Data Science Intern',
        'duration': 'July 2023 - September 2023',
        'logo': 'https://1000logos.net/wp-content/uploads/2021/08/Capgemini-Logo.png',
        'points': [
            'Development of an in-house application to automate the Excel file analysis process. 📈',
            'Implemented data security for the company’s product to provide role-based access control ⚙️.'
        ],
        'url': 'https://www.capgemini.com/',
    }
]    



export const EMAIL_JS_SERVICE_ID = 'service_gaso0cf';
export const EMAIL_JS_TEMPLATE_ID_FOR_ME = 'template_7dvvuvo';
export const EMAIL_JS_TEMPLATE_ID_FOR_CLIENT = 'template_sdmu5xw';
export const EMAIL_JS_PUBLIC_KEY = 'Y5XIQrSCJ_Xgi76TO';